<template>
  <div :class="{'open': true, 'scrolledTop': scrollTop > 68}">
    <top-header class="doc-t-b" :navs="navs"> </top-header>
    <div class="ability">
      <div class="title-box">
          全链条自研AI技术
      </div>
      <el-row class="ability-row" :gutter="24">
        <el-col :xs="{span:24}" :md="{span:11}" class="ability-text">
          <h2 class="featurette-heading">基础控制能力</h2>
          <p class="lead">头部控制，灯效控制，箱门控制，camera拍照，休眠，定时开关机，低电自动回充。</p>
        </el-col>
        <el-col :xs="{span:24}" :md="{span:13}">
          <img src="@/assets/images/open/baoxiaomi-head.png" alt="豹小秘-头部" style="width: 100%; height: 100%" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto">
        </el-col>
      </el-row>
      <el-row class="ability-row" :gutter="24">
        <el-col :xs="{span:24}" :md="{span:13}">
          <img src="@/assets/images/open/baoxiaomi-mouse.png" alt="豹小秘-头部" style="width: 100%; height: 100%" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto">
        </el-col>
        <el-col :xs="{span:24}" :md="{span:11}" class="ability-text">
          <h2 class="featurette-heading">语音开放能力</h2>
          <p class="lead">
            ASR：自定义唤醒词，唤醒角度调整，vad截断设置，单次识别，长识别<br>
            NLU：行业问答配置，问答库，专属词纠错，阅读理解，知识图谱，Api接入，语音报表<br>
            TTS：发言人定制，音量调节，声色选择，语速设置</p>
        </el-col>
      </el-row>
      <el-row class="ability-row" :gutter="24">
        <el-col :xs="{span:24}" :md="{span:11}" class="ability-text">
          <h2 class="featurette-heading">视觉开放能力</h2>
          <p class="lead">距离检测，人体检测识别，人脸检测识别，物体识别，手势识别，注册，焦点跟随，人流量统计，人信息识别（性别，年龄，衣着，颜色等等），视觉建图。</p>
        </el-col>
        <el-col :xs="{span:24}" :md="{span:13}">
          <img src="@/assets/images/open/baoxiaomi-eye.png" alt="豹小秘-头部" style="width: 100%; height: 100%" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto">
        </el-col>
      </el-row>
      <el-row class="ability-row" :gutter="24">
        <el-col :xs="{span:24}" :md="{span:13}">
          <img src="@/assets/images/open/baoxiaomi-leg.png" alt="豹小秘-头部" style="width: 100%; height: 100%" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto">
        </el-col>
        <el-col :xs="{span:24}" :md="{span:11}" class="ability-text">
          <h2 class="featurette-heading">运动开放能力</h2>
          <p class="lead">多模态导航，多形式避障，雷达定位，IR定位，视觉定位，多语种定点，多楼层建图，多机协作。</p>
        </el-col>
      </el-row>
    </div>
    <div class="opk">
      <div class="title-box">
        <div class="title">
          开放灵活的机器人小程序定制商店
        </div>
        <div class="introduce">
          扩展机器人技能中心能力，支持自研和第三方机器人小程序接入，像手机、pad、网络<div class="introduce-br"></div>
          电视的“应用商店”一样，自由安装，打造机器人小程序生态系统。
        </div>
      </div>
      <div class="baoxiaomi-opk-store">
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-outside-map.png" class="figure-img img-fluid" alt="室外地图">
          <p>室外地图</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-advertise.png" class="figure-img img-fluid" alt="广告宣传">
          <p>广告宣传</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-message.png" class="figure-img img-fluid" alt="留言板">
          <p>留言板</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-photograph.png" class="figure-img img-fluid" alt="拍照">
          <p>拍照</p>
        </div>
        <div class="figure text-center min-none">
          <img src="@/assets/images/open/baoxiaomi-opk-weather.png" class="figure-img img-fluid" alt="天气">
          <p>天气</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-calendar.png" class="figure-img img-fluid" alt="日历">
          <p>日历</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-exercise.png" class="figure-img img-fluid" alt="基础运动">
          <p>基础运动</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-face.png" class="figure-img img-fluid" alt="人气注册">
          <p>人脸注册</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-ppt.png" class="figure-img img-fluid" alt="PPT讲解">
          <p>PPT讲解</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-guide.png" class="figure-img img-fluid" alt="问路引领">
          <p>问路引领</p>
        </div>
        <div class="figure text-center">
          <img src="@/assets/images/open/baoxiaomi-opk-recept.png" class="figure-img img-fluid" alt="访问接待">
          <p>访问接待</p>
        </div>
        <div class="figure text-center min-none">
          <img src="@/assets/images/open/baoxiaomi-opk-dance.png" class="figure-img img-fluid" alt="跳舞">
          <p>跳舞</p>
        </div>
      </div>
    </div>
    <div class="doc-page-foot">
      <div class="foot-navs">
        <a href="/doc" @click.prevent="toDoc">文档中心</a>
        <a href="/doc" @click.prevent="toDoc">关于我们</a>
        <a href="mailto:OrionBase@ainirobot.com"
           @click="$utils.GATracking('点击意见反馈','点击','首页')">意见反馈</a>
        <a href="/doc" @click.prevent="toDoc">用户协议</a>
        <a href="/doc" @click.prevent="toDoc">隐私政策</a>
      </div>
      <div class="cn-bei">
        <a
                target='_blank'
                href='http://www.beian.miit.gov.cn'
                @click="$utils.GATracking('备案号','点击','首页底导-ICP备案号')"
        >猎户星空©2019  • 京公网安备 11010502039185号，京ICP备17016764号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
    import topHeader from '@/components/common/top-header'
    const NAVS = [
        {
            title: '首页',
            route_name: 'home',
            path: '/'
        },
        {
            title: '文档中心',
            route_name: 'doc',
            path: '/doc'
        }
    ]
    export default {
        components: {
            topHeader
        },
        data() {
            return {
                navs: NAVS,
                scrollTop: 0,
            }
        },
        props: [],
        computed: {
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll)
            this.handleScroll()
        },
        methods: {
            handleScroll(e) {
                let scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                this.scrollTop = scrollTop;
            },
        }
    }
</script>

<style lang="less">
  .scrolledTop {
    .top-header{
      background:  #f4f5f6;
      box-shadow: 0px 1px 9px 0px rgba(41, 43, 57, 0.2);
      .el-dropdown-link {
        background: #f4f5f6!important;
      }
    }
  }
  .open {
    .top-header:hover {
      background:  #f4f5f6;
      box-shadow: 0px 1px 9px 0px rgba(41, 43, 57, 0.2);
      .el-dropdown-link {
        background: #f4f5f6!important;
      }
    }
    width: 80%;
    margin: 100px auto;
    padding: 30px 0;
    .ability {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #222;
      .ability-row.el-row {
         margin: 30px 0;
      }
      .ability-text {
        padding-top: 100px;
        .featurette-heading {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 60px;
        }
        .lead {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
        }
      }

    }
    .opk {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      color: #222;
      .title {
        margin-top: 50px;
      }
      .introduce {
        font-size: 16px;
        font-weight: 400;
        margin:30px 0;
      }
      .baoxiaomi-opk-store {
        font-size: 16px;
        font-weight: 400;
        .figure {
          width: 16%;
          text-align: center;
          display: inline-block;
          p {
            margin-bottom: 20px;
          }
        }
      }
    }
    .doc-page-foot {
      padding: 100px 0 10px 70px;
      /*justify-content: space-around;*/
      font-size: 14px;
      color: rgba(27, 26, 58, 0.6);
      text-align: center;
      a {
        text-decoration: none;
        font-size: 14px;
        color: rgba(27, 26, 58, 0.6);
        margin-right: 30px;
        &:hover {
          text-decoration: underline;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
      .foot-navs, .cn-bei {
        line-height: 2;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .open .ability .ability-text {
      padding-top: 0;
    }
    .open .ability .ability-text .featurette-heading {
      margin-bottom: 10px;
    }
    .open .ability .ability-text .lead {
      text-align:  center;
      padding-bottom: 10px;
    }
    .opk .baoxiaomi-opk-store .figure {
      width: 25%;
    }
  }
  @media screen and (max-width: 900px) {
    .open .opk .baoxiaomi-opk-store .figure {
      width: 25%;
    }
  }
</style>
